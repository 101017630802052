@import '../../assets/styles/screenSizes.scss';

.login-container {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .form-container {
        // border: solid;
        // align-self: center;
        z-index: 2;

        background-color: white;
        margin: auto;
        width: 500px;
        padding: 2.5rem 1.5rem;
        border-radius: 1rem;
        text-align: left;
        box-shadow: 0 2.3px 1.9px -40px rgba(0, 0, 0, 0.018), 0 5.4px 4.3px -40px rgba(0, 0, 0, 0.026),
            0 9.7px 7.7px -40px rgba(0, 0, 0, 0.032), 0 16.1px 12.8px -40px rgba(0, 0, 0, 0.038),
            0 26.5px 21.2px -40px rgba(0, 0, 0, 0.044), 0 46.2px 37px -40px rgba(0, 0, 0, 0.052),
            0 100px 80px -40px rgba(0, 0, 0, 0.07);
        form {
            display: flex;
            flex-direction: column;
            // align-items: center;
            background-color: white;
            h2 {
                align-self: center;
            }

            .forget-password {
                text-align: right;
                margin-top: 1rem;
                // margin-bottom: 0;
            }

            h5,
            h6 {
                align-self: center;
                font-weight: 400;
                margin-top: 1.5rem;
                text-align: center;
            }

            h5 {
                margin-bottom: 1.5rem;
                font-size: 1rem;
            }

            .social-btns-container {
                // border: solid;
                display: flex;
                justify-content: center;
                img {
                    width: 3rem;
                    height: 3rem;
                    margin: 0 0.5rem;
                    cursor: pointer;
                }
            }
            button {
                width: 50%;
                border-radius: 2rem;
                padding: 0.5rem 1rem;
                text-align: center;
                align-self: center;
                margin-top: 1.5rem;
                font-weight: 600;
                // margin: auto;
                // border: solid;
            }
        }
    }

    svg {
        position: fixed;
        bottom: 0;
        // height: 20%;
        width: 100vw;
        // border: solid;
        z-index: 1;

        path {
            width: 100vw;
        }
    }
}

@media screen and (max-width: $medium) {
    .login-container {
        .form-container {
            max-width: 95%;
            padding: 2rem 0.5rem;
            box-shadow: none;
        }
    }
}
