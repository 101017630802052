@import '../../assets/styles/colors.scss';

.note-card-container {
    min-height: 150px;
    width: 100%;
    max-width: 100%;
    border-radius: 0.7rem;
    padding: 1.5rem;
    font-size: 1rem;
    line-height: 1.4rem;

    display: inline-flex !important;
    flex-direction: column;
    justify-content: space-between;

    margin-bottom: 0.75rem;

    cursor: pointer;
    border: none;
    &.yellow {
        background-color: $yellow;
    }
    &.orange {
        background-color: $orange;
    }

    &.purple {
        background-color: $purple;
    }
    &.blue {
        background-color: $blue;
    }
    &.green {
        background-color: $green;
    }

    &:hover {
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }

    .fav-icon-container {
        width: 2rem;
        height: 2rem;
        background-color: black;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-left: auto;
        opacity: 0.6;

        img {
            width: 0.9rem;
        }

        &.active {
            opacity: 1;
        }
    }

    .title {
        font-weight: 700;
        margin-bottom: 0.75rem;
    }

    .title,
    .content {
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre;
    }

    .bottom-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0;
        margin-top: 0.5rem;
        margin-top: auto;
        p {
            margin: 0;
        }
        .date {
            font-size: 0.9rem;
            font-weight: 300;

            p {
                margin: 0;
            }
        }

        .edit-icon-container,
        .fav-icon-container {
            width: 2rem;
            height: 2rem;
            background-color: black;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            visibility: hidden;

            img {
                width: 0.9rem;
            }
        }
    }
}
