@import '../../assets/styles/colors.scss';
@import '../../assets/styles/screenSizes.scss';

.side-bar-container {
    // border: solid;
    width: 110px;
    max-width: 10%;
    padding: 1rem;
    border-right: 1px solid #505050;

    display: flex;
    flex-direction: column;
    align-items: center;

    height: 100vh;
    position: fixed;

    .brand {
        font-size: 1.7rem;
    }

    .add-container {
        margin-top: 4rem;

        .note-dots-container {
            // border: solid;
            margin-top: 2.5rem;

            display: flex;
            flex-direction: column;
            align-items: center;
            .note-dot {
                width: 1.25rem;
                height: 1.25rem;
                border-radius: 50%;
                background-color: black;
                margin-bottom: 1rem;
                cursor: pointer;

                // animation: bounce; /* referring directly to the animation's @keyframe declaration */
                // animation-duration: 0.5s; /* don't forget to set a duration! */

                &:last-child {
                    margin-bottom: 0;
                }

                &.yellow {
                    background-color: $yellow;
                    animation-duration: 0.2s; /* don't forget to set a duration! */
                }
                &.orange {
                    background-color: $orange;
                    animation-duration: 0.5s; /* don't forget to set a duration! */
                }
                &.purple {
                    background-color: $purple;
                    animation-duration: 0.8s; /* don't forget to set a duration! */
                }
                &.blue {
                    background-color: $blue;
                    animation-duration: 1.1s; /* don't forget to set a duration! */
                }
                &.green {
                    background-color: $green;
                    animation-duration: 1.4s; /* don't forget to set a duration! */
                }
            }
        }
    }

    .icon-container {
        width: 2.5rem;
        height: 2.5rem;
        background-color: black;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        // padding: 0.5rem;
        img {
            width: 1rem;
        }
    }

    .logout-icon-container {
        background-color: transparent;
        margin-top: auto;
        img {
            width: 2.5rem;
            height: 2.5rem;
        }
    }
}

@media screen and (max-width: $medium) {
    .side-bar-container {
        max-width: none;
        // border: solid;
        width: 100%;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        position: relative;
        height: auto;

        .brand {
            font-size: 1.7rem;
        }
        .add-container {
            margin-top: 0;
            margin-left: auto;
            position: relative;
            // border: solid;
            .note-dots-container {
                position: absolute;
                top: 1.5rem;
                left: -0.5rem;
                z-index: 1;

                background-color: white;
                padding: 1rem 1rem;
                border-radius: 4rem;
                box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
            }
        }

        .logout-icon-container {
            // background-color: transparent;
            // margin-top: auto;
            margin-top: 0;
            margin-left: 1.5rem;
            img {
                width: 2.5rem;
                height: 2.5rem;
            }
        }
    }
}

@media screen and (max-width: $small) {
    .side-bar-container {
        .add-container {
            .icon-container {
                width: 2rem;
                height: 2rem;
            }
        }

        .logout-icon-container {
            img {
                width: 2rem;
                height: 2rem;
            }
        }
    }
}
