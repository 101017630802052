@import '../../assets/styles/colors.scss';
@import '../../assets/styles/mixins.scss';
@import '../../assets/styles/screenSizes.scss';

.home-container {
    @include fullScreen;
    display: flex;

    .title {
        font-size: 2.5rem;
        text-align: left;
    }
    .sub-title {
        font-size: 1.5rem;
        text-align: left;
    }

    .content {
        width: 100%;
        padding: 4rem;

        width: calc(100% - 100px);
        margin-left: auto;

        .list-of-cards {
            display: flex;
            flex-wrap: wrap;
            display: inline-flex;
            flex-wrap: wrap;

            --gap: 1rem;
            display: inline-flex;
            flex-wrap: wrap;
            margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
            width: calc(100% + var(--gap));
        }
    }
}

@media screen and (max-width: $medium) {
    .home-container {
        flex-direction: column;
        .content {
            width: 100%;
        }
    }
}

@media screen and (max-width: $small) {
    .home-container {
        .content {
            padding: 1rem;
        }
    }
}
