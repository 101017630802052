@import '../../assets/styles/colors.scss';

.modal-dialog {
    max-width: 600px;
    /* margin: auto; */
    /* border: solid; */
    margin-top: 15%;
    position: relative;
    animation-duration: 0.4s; /* don't forget to set a duration! */

    .modal-content {
        border: none;
        &.yellow {
            background-color: $yellow;
        }
        &.orange {
            background-color: $orange;
        }

        &.purple {
            background-color: $purple;
        }
        &.blue {
            background-color: $blue;
        }
        &.green {
            background-color: $green;
        }
    }

    .modal-header {
        border-bottom: none;
        // border: solid;
        .modal-title {
            width: 100%;
            // border: solid;
            position: relative;
            .title-text-area {
                // padding: 1rem;
                padding-right: 1.5rem;
                font-size: 1.3rem;
                box-shadow: none;
                border: none;
                // overflow: hidden;
                resize: none;
                // min-height: 50px;
                height: auto;
                background-color: transparent;
                &::-webkit-scrollbar {
                    display: none;
                }
            }

            // input {

            // }
        }
    }

    .text-area {
        border: none;
        box-shadow: none;
        font-size: 1.1rem;
        background-color: transparent;
    }

    .date-container {
        display: flex;
        flex-direction: row-reverse;
        margin-top: 0.5rem;
        .date {
            text-align: right;
            font-size: 0.9rem;
            color: ffffffcc;
        }
    }

    .actions-here {
        // border: solid;
        display: flex;
        padding: 0.5rem;
        .icon-container {
            // border: solid;
            width: 2rem;
            height: 2rem;
            background-color: black;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            // visibility: hidden;
            // position: absolute;
            // top: -4%;
            // right: -10px;
            // padding: 0.5rem;
            margin: 0 0.25rem;
            img {
                width: 0.9rem;
            }
            &.fav-icon {
                opacity: 0.6;
            }
            &.active {
                opacity: 1;
            }
        }
        .color-icon {
            position: relative;
            border: solid;
            .colors-container {
                width: 170px;
                // border: solid;
                position: absolute;
                left: 1rem;
                top: -2.2rem;
                display: flex;
                flex-wrap: wrap;
                background-color: white;
                border-radius: 0.5rem 0.5rem 0.5rem 0;
                .color-dot {
                    width: 1rem;
                    height: 1rem;
                    margin: 0.5rem 0.5rem;
                    border-radius: 50%;
                    border: 1px solid white;
                    animation-duration: 0.25s;
                    &.yellow {
                        background-color: $yellow;
                    }
                    &.orange {
                        background-color: $orange;
                    }

                    &.purple {
                        background-color: $purple;
                    }
                    &.blue {
                        background-color: $blue;
                    }
                    &.green {
                        background-color: $green;
                    }
                }
            }
        }
    }
}
