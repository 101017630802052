@import './assets/styles/colors.scss';
.App {
    text-align: center;

    background-color: #fffeff;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // font-size: calc(10px + 2vmin);
    color: $dark;

    &.dark {
        background-color: #202124;
        color: #fffeff;
    }

    a {
        text-decoration: none;
    }
}
