// @import 'bootstrap';
@import '../../assets/styles/screenSizes.scss';
.card-columns {
    column-count: 6;
}
@media screen and (max-width: 1366px) {
    .card-columns {
        column-count: 5;
    }
}
@media screen and (max-width: $xLarge) {
    .card-columns {
        column-count: 3;
    }
}
@media screen and (max-width: $large) {
    .card-columns {
        column-count: 2;
    }
}
@media screen and (max-width: $medium) {
    .card-columns {
        column-count: 2;
    }
}

@media screen and (max-width: $small) {
    .card-columns {
        column-count: 1;
    }
}
